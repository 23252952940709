import React, { useState } from 'react';
import styles from './index.module.scss';
import { Breadcrumb } from '~/common/models';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import {
  Breadcrumbs,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { desktopMin, tabletMax } from '~/common/utils';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';
import { useNavOffsetHeight } from '~/common/hooks/use-navOffsetHeight';

interface IHeaderProps {
  heading?: string;
  headerDisclaimer?: string;
  logoSrc?: string;
  breadcrumbs?: Breadcrumb[];
}

export const Header = React.memo((props: IHeaderProps) => {
  const { heading, headerDisclaimer, logoSrc, breadcrumbs } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );
  useRTETooltipPositionCorrector(isMobile);

  const defaultHeightOffset = () => (isMobile ? 55 : 85);
  const offsetHeight = useNavOffsetHeight(isMobile, defaultHeightOffset());

  const renderHeading = () => {
    return (
      <div className={styles.Heading}>
        <div dangerouslySetInnerHTML={{ __html: heading as string }} />
        {headerDisclaimer && (
          <TooltipDisclaimer
            triggerClassName={styles.HeaderDisclaimerTrigger}
            disclaimer={headerDisclaimer}
          />
        )}
      </div>
    );
  };

  const renderLogo = () => {
    return (
      <>
        {logoSrc && (
          <div className={styles.LogoContainer}>
            <img className={styles.Logo} src={logoSrc} loading="lazy" />
          </div>
        )}
      </>
    );
  };

  const renderBreadcrumbs = () => (
    <Breadcrumbs className={styles.Breadcrumbs} breadcrumbs={breadcrumbs} />
  );

  return (
    <div
      ref={ref}
      className={cn('full-device-width', styles.HeaderContainer)}
      style={{ paddingTop: `${offsetHeight}px` }}
    >
      <MediaQuery minWidth={desktopMin}>
        <>
          <div className={styles.Header}>
            {renderBreadcrumbs()}
            {renderLogo()}
          </div>
          {renderHeading()}
        </>
      </MediaQuery>
      <MediaQuery maxWidth={tabletMax}>
        <>
          <div className={styles.Header}>{renderLogo()}</div>
          {renderBreadcrumbs()}
          {renderHeading()}
        </>
      </MediaQuery>
    </div>
  );
});
